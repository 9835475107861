import './index.css';

import { LicenseInfo } from '@mui/x-license-pro';
import Bootstrapper from '@witron/asl_core/Bootstrapper';
import BaseModule from '@witron/asl_core/ModuleManager/BaseModule';
import Shell from '@witron/asl_shell/Shell';

LicenseInfo.setLicenseKey('5261bf7371b10525d92158b6193fa3afTz04MzE4MSxFPTE3MzgyMjU5MTAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

async function importModules(): Promise<Array<BaseModule<any, any>>> {
    const modules: Array<BaseModule<any, any>> = [];

    const shellModules = await Shell.importModules();
    modules.concat(shellModules);

    // webpackPrefetch: true

    const importedCore = await import(/* webpackChunkName: "witron/asl/core_module/index" */ '@witron/asl_core_module/Core/CoreModule');
    modules.push(importedCore.CoreModule.getInstance());

    const importedBWos = await import(/* webpackChunkName: "witron/asl/core_module/index" */ '@witron/asl_core_module/BWosSo/BWosSoModule');
    modules.push(importedBWos.BWosSoModule.getInstance());

    const importedBMIS = await import(/* webpackChunkName: "witron/abl/bmis/index" */ '@witron/abl_bmis/BMIS/BMISModule');
    modules.push(importedBMIS.BMISModule.getInstance());

    const importedBI = await import(/* webpackChunkName: "witron/abl/bi/index" */ '@witron/abl_bi/BI/BIModule');
    modules.push(importedBI.BIModule.getInstance());

    const importedBGif = await import(/* webpackChunkName: "witron/abl/bgif/index" */ '@witron/abl_bgif/BGIF/BGIFModule');
    modules.push(importedBGif.BGIFModule.getInstance());

    // const importedBPFP = await import(/* webpackChunkName: "witron/abl/bpfp/index" */ '@witron/abl_bpfp/BPFP/BPFPModule');
    // modules.push(importedBPFP.BPFPModule.getInstance());

    const importedBWOS = await import(/* webpackChunkName: "witron/abl/bwos/index" */ '@witron/abl_bwos_module/BWOS/BWosModule');
    modules.push(importedBWOS.BWosModule.getInstance());

    return modules;
}

async function startUp() {
    await Bootstrapper.startUpStandalone('root', Shell.App, importModules, Shell.importPlugins);
}

window.requestAnimationFrame(startUp);
